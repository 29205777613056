import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';
import { fetchAllTanda, fetchAllByTanggalFilter } from 'redux/features/tanda';
import CsvDownloadButton from 'react-json-to-csv'
import { exportToExcel } from 'react-json-to-excel';
import { Select, Modal } from 'antd';

const selectStyle = {
    width: "24%",
    backgroundColor: "white",
    marginRight: "2%"
};

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

const options = [
    {
        value: 'SEMUA',
        label: 'SEMUA',
    },
    {
        value: 'KELUAR',
        label: 'KELUAR',
    },
    {
        value: 'MASUK',
        label: 'MASUK',
    }
]

export const TANDA_TERIMA = () => {
    const history = useHistory()
    const [isLoading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const { Search } = Input
    const [startDateState, setStartDate] = useState()
    const [endDateState, setEndDate] = useState()
    const [perihal, setPerihal] = useState()
    const [jenisTanda, setJenisTanda] = useState()

    const {
        list,
        selectedRows,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.tanda)

    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllByTanggalFilter({
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            })).unwrap()
            setLoading(false)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    const cariTandaLengkap = useCallback(async (perihal,jenisTanda,startDate,endDate) => {
        try {
            await dispatch(fetchAllTanda({
                perihal,
                jenisTanda,
                startDate,
                endDate
            })).unwrap()
            setLoading(false)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    // const getDataWithTanggal = useCallback(async (startDate, endDate) => {
    //     try {
    //         await dispatch(fetchAllByTanggalFilter({
    //             startDate: startDate,
    //             endDate: endDate
    //         })).unwrap()
    //         setLoading(false)
    //     } catch (error) {
    //         message.error('Gagal mencari data pastikan kedua tanggal sudah terisi!')
    //     }
    // }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: 'Tanggal',
            dataIndex: 'tanggalTanda',
            render: (_, record) => {
                return (
                    moment(record.tanggalSurat).format("DD-MMM-YY")
                )
            }
        },
        {
            title: 'Nomor Reg Lama',
            dataIndex: 'nomorRegLama',
            key: 'nomorRegLama',
        },
        {
            title: 'Nomor Reg Baru',
            dataIndex: 'nomorRegBaru',
            key: 'nomorRegBaru',
        },
        {
            title: 'Tanda Terima',
            dataIndex: 'tandaTerima',
            key: 'tandaTerima',
        },
        {
            title: 'Type',
            dataIndex: 'tipe',
            key: 'tipe',
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: 'Req By',
            dataIndex: 'reqBy',
            key: 'reqBy',
        },
        {
            title: 'PIC',
            dataIndex: 'pic',
            key: 'pic',
        },
        {
            title: 'Yang Menyerahkan',
            dataIndex: 'ygMenyerahkan',
            key: 'ygMenyerahkan',
        },
        {
            title: 'Yang Menerima',
            dataIndex: 'ygMenerima',
            key: 'ygMenerima',
        },
        {
            title: 'Yang Mengetahui',
            dataIndex: 'ygMengetahui',
            key: 'ygMengetahui',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Soft File',
            dataIndex: 'externalFile',
            render: (_, record) => {
                return (
                    <a target='_blank' href={record.externalFile}>Cek File</a>
                )
            },
        },
        {
            title: 'Hard',
            dataIndex: 'hard',
            key: 'hard',
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'status',
            render: (_, record) => (
                <div className="text-center">
                    <a type="primary" style={{ width: "70%" }} onClick={() => {
                        history.push({
                            pathname: '/app/detail-tanda-terima-masuk',
                            id: record._id,
                        })
                    }} >Edit</a>
                </div>
            ),
        },
    ];

    const onSearch = async (event) => {
        setPerihal(event.target.value)
    }

    const selectJenisTanda = (value) => { 
        setJenisTanda(value) 
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Tanda Terima di Tanggal: {moment().format("DD-MMM-YY")}</h2>
                    <p>Semua Tanda yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan Tanda Terima"
                                onChange={onSearch}
                                style={{
                                    width: "49%",
                                    marginRight: "2%"
                                }}
                            />

                            <DatePicker
                                onChange={(value) => {
                                    setStartDate(moment(value).format("YYYY-MM-DD"))
                                }}
                                style={{
                                    width: "24%",
                                    marginRight: "2%"
                                }} placeholder='Tanggal Mulai' />
                            <DatePicker
                                onChange={(value) => {
                                    setEndDate(moment(value).format("YYYY-MM-DD"))
                                }}
                                style={{
                                    width: "24%",
                                    marginRight: "2%"
                                }} placeholder='Tanggal Selesai' />

                            <Select
                                defaultValue={"Jenis Tanda"}
                                style={selectStyle}
                                onChange={selectJenisTanda}
                                options={options}
                            />

                            <Button type="primary" style={{width:"25%"}} onClick={() => { cariTandaLengkap(perihal,jenisTanda,startDateState, endDateState) }}>Cari Tanda</Button>
                        </div>
                        <Table
                        scroll={{ x: 1500, y: 300 }}
                            className="no-border-last"
                            columns={tableColumns}
                            loading={isLoading}
                            dataSource={list}
                            pagination={{ pageSize: 5 }}
                            rowKey='id'
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px", width: "100%" }} onClick={() => exportToExcel(list, 'report')}>Download as Excel</Button>
                </Col>
            </Row>
            <br></br>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <CsvDownloadButton style={{ width: "100%", padding: "3px" }} data={list}>Download as CSV</CsvDownloadButton>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(TANDA_TERIMA);
