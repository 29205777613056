import moment from "moment-timezone";

export const strings = {
  today: moment().tz("Asia/Jakarta").format("YYYY-MM-DD"),
  tomorrow: moment().add(1, 'days').tz("Asia/Jakarta").format("YYYY-MM-DD"),
  token: localStorage.getItem('token'),
  api: {
    host: "https://api.surabah.tech",
    storage: "https://storage.surabah.tech/upload",
    HEADER_KEY: "mantapjiwa",
    JWT_KEY: "Aj1257Xi202",
  },
  image: {
    authBackground: 'url(/img/background.png)',
    logo: "/img/logo.png",
    logo2: "/img/logo-colored.png",
    favicon: "",
    primaryColor: "#1445e4",
    buttonColor: "#6e17f7",
    color1: "#0675b5",
    color2: "#10a8f0",
    color3: "#0077b6",
    color4: "#FF0000",
    color5: "#005578",
    color6: "#FFAA00"
  },
  auth_form: {
    isUnavailableAccount: "Belum punya akun? ",
    sign_up: "Daftar",
    sign_in: "Masuk",
    alreadyHaveAccount: "Sudah punya akun? ",
    create_account: "Create a new account:",
  },
  default: {
    title_app: "Surabah Dashboard",
  },
  navigation: {
    login: '/auth/login',
    register: '/auth/register',
    main: '/app',
    path: {
      login: 'login',
      register: 'register',
      forgot_password: 'forgot-password',
      activate: 'activate',
      resend:"resend",
      please: 'please',
      logout: "logout",
      dashboard: "/app/dashboard",
      surat_masuk: "/app/surat-masuk",
      cari_surat: "/app/cari-surat",
      cari_tanda_terima: "/app/cari-tanda-terima",
      laporan: "/app/laporan",
      surat_keluar: "/app/surat-keluar",
      tanda_terima_keluar: "/app/tanda-terima-keluar",
      tanda_terima_masuk: "/app/tanda-terima-masuk",
      detail_surat_masuk: "/app/detail-surat-masuk",
      detail_surat_keluar: "/app/detail-surat-keluar",
      detail_tanda_terima_masuk: "/app/detail-tanda-terima-masuk",
      detail_tanda_terima_keluar: "/app/detail-tanda-terima-keluar",
      detail_laporan: "/app/detail-laporan",
      detail_pengguna: "/app/detail-pengguna",
      pengguna: "/app/pengguna",
      profile: "/app/profile",
      settings: "/app/settings"
    }
  }
};
