import { Col, Row, message } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { addPengguna, fetchOnePengguna, updatePengguna, deletePengguna } from 'redux/features/pengguna';


const selectStyle = {
  width: "100%",
  backgroundColor: "white"
};

const rules = [
  {
    required: true,
    message: 'Wajib Diisi!',
  }
]

const options = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'user',
    label: 'User',
  }
]

export const ADD_PENGGUNA = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation()

  const [role, setRole] = useState("user")

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setRole(value)
  };

  const onFinish = (values) => {
    if (location.id) {
      updateUser(values)
    } else {
      createUser(values)
    }
  }

  const createUser = async (values) => {
    try {
      await dispatch(addPengguna({
        ...values,
        role
      })).unwrap()
      history.push("/app/pengguna")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const updateUser = async (values) => {
    try {
      await dispatch(updatePengguna({
        ...values,
        role
      })).unwrap()
      history.push("/app/pengguna")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const dataUser = await dispatch(fetchOnePengguna(id)).unwrap()
      console.log(dataUser)
      form.setFieldsValue({
        ...dataUser[0]
      });
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (location.id) {
      getDataById(location.id)
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Atur Pengguna</h2>
          <p>Atur pengguna dan privilege pengguna</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={rules}
              >
                <Select
                  defaultValue="Belum Dipilih"
                  style={selectStyle}
                  onChange={handleChange}
                  options={options}
                />
              </Form.Item>

              <Form.Item
                label="Set New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item

              >
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Tambah/Update  Pengguna
                </Button>
                <br />
                <br />
                <Button onClick={() => {
                  if (location.id) {
                    dispatch(deletePengguna(location.id))
                    history.push("/app/pengguna")
                  }else{
                    message.error("Tidak bisa menghapus user!")
                  }
                }} type="danger" htmlType="submit" style={{ width: "100%", color: "white" }}>
                  Delete Pengguna
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ADD_PENGGUNA