import React, {  Suspense } from "react";
import { Switch,Redirect} from "react-router-dom";
import PrivateRoute from 'components/PrivateRoute'
import { strings } from "res";
import Loading from 'components/shared-components/Loading';

// Pages
import DASHBOARD from "./dashboard"
import SURAT_MASUK from "./surat-masuk"
import SURAT_KELUAR from "./surat-keluar"
import TANDA_TERIMA_MASUK from "./tanda-terima-masuk"
import TANDA_TERIMA_KELUAR from "./tanda-terima-keluar"
import PENGGUNA from "./pengguna"
import LAPORAN from "./laporan"
import CARI_SURAT from "./cari-surat"

// Detail
import DETAIL_SURAT_KELUAR from "./detail-surat-keluar"
import DETAIL_SURAT_MASUK from "./detail-surat-masuk"
import DETAIL_TANDA_TERIMA_MASUK from "./detail-tanda-terima-masuk"
import DETAIL_TANDA_TERIMA_KELUAR from "./detail-tanda-terima-keluar"
import DETAIL_PENGGUNA from "./detail-pengguna"

import SETTINGS from "./settings"
import { TANDA_TERIMA } from "./cari-tanda-terima";

export const AppViews = ({match}) => {

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <PrivateRoute path={`${strings.navigation.path.dashboard}`} component={DASHBOARD} />
        <PrivateRoute path={`${strings.navigation.path.surat_masuk}`} component={SURAT_MASUK} />
        <PrivateRoute path={`${strings.navigation.path.surat_keluar}`} component={SURAT_KELUAR} />
        <PrivateRoute path={`${strings.navigation.path.tanda_terima_masuk}`} component={TANDA_TERIMA_MASUK} />
        <PrivateRoute path={`${strings.navigation.path.tanda_terima_keluar}`} component={TANDA_TERIMA_KELUAR} />
        <PrivateRoute path={`${strings.navigation.path.pengguna}`} component={PENGGUNA} />
        <PrivateRoute path={`${strings.navigation.path.laporan}`} component={LAPORAN} />
        <PrivateRoute path={`${strings.navigation.path.cari_tanda_terima}`} component={TANDA_TERIMA} />
        <PrivateRoute path={`${strings.navigation.path.cari_surat}`} component={CARI_SURAT} />

        {/* DETAIL */}
        <PrivateRoute path={`${strings.navigation.path.detail_surat_masuk}`} component={DETAIL_SURAT_MASUK} />
        <PrivateRoute path={`${strings.navigation.path.detail_surat_keluar}`} component={DETAIL_SURAT_KELUAR} />
        <PrivateRoute path={`${strings.navigation.path.detail_tanda_terima_masuk}`} component={DETAIL_TANDA_TERIMA_MASUK} />
        <PrivateRoute path={`${strings.navigation.path.detail_tanda_terima_keluar}`} component={DETAIL_TANDA_TERIMA_KELUAR} />
        <PrivateRoute path={`${strings.navigation.path.detail_pengguna}`} component={DETAIL_PENGGUNA} />
        <PrivateRoute path={`${strings.navigation.path.settings}`} component={SETTINGS} />

        <Redirect from={`${match.url}`} to={`${strings.navigation.path.dashboard}`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
