import { Col, Row } from 'antd';
import React from "react";
import { Card, Form, Input } from 'antd';
import { Select, DatePicker, Modal } from 'antd';
import { Image, Video, Transformation } from 'cloudinary-react';
import { Spin } from 'antd';
import { Button, message, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import { addTanda, deleteTanda, fetchOneTanda, updateTanda } from 'redux/features/tanda';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import { strings } from '../../../res/strings/index'


const { TextArea } = Input;

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

const options = [
    {
        value: 'BELUM',
        label: 'BELUM',
    },
    {
        value: 'SUDAH',
        label: 'SUDAH',
    }
]

export const DETAIL_TANDA_TERIMA_MASUK = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState(null);
    const location = useLocation()
    const [Tanda, setTanda] = useState({
        _id: "",
        externalFile: "",
        jenisTanda: "MASUK",
        judulTanda: "",
        keterangan: "",
        linkFile: "",
        nomorTanda: "",
        statusMaterai: "",
        statusTanda: "",
        tanggalTanda: ""
    })

    const [isLoading,setLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [statusTandaState, setStatusTanda] = useState("");
    const [statusMateraiState, setStatusMaterai] = useState("");

    const handleOk = async () => {
        try {
            setVisible(false);
            await dispatch(deleteTanda(Tanda._id)).unwrap()
            history.push({
                pathname: '/app/tanda-terima-masuk'
            })
        } catch (err) {
            console.log(err)
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const createTandaMethod = async (values) => {
        try {
            await dispatch(addTanda({
                ...values,
                externalFile: imageUrl,
                statusTanda: statusTandaState,
                statusMaterai: statusMateraiState,
                jenisTanda: "MASUK",
                tanggalTanda: moment(values.tanggalTanda).format("YYYY-MM-DD")
            })).unwrap()
            message.success("Tanda Berhasil Dibuat! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/tanda-terima-masuk'
                })
            }, 1000)
        } catch (err) {
            message.error(err?.message || 'Failed to fetch data')
            console.log(err)
        }
    }

    const updateTandaMethod = async (values) => {
        try {
            await dispatch(updateTanda({
                id: Tanda._id,
                ...values,
                externalFile: imageUrl,
                statusTanda: statusTandaState,
                statusMaterai: statusMateraiState,
                tanggalTanda: moment(values.tanggalTanda).format("YYYY-MM-DD")
            })).unwrap()
            message.success("Tanda Berhasil Diupdate! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/tanda-terima-masuk'
                })
            }, 1000)
        } catch (err) {
            message.error(err?.message || 'Failed to fetch data')
            console.log(err)
        }
    }

    const onFinish = async (values) => {
        try {
            if (location.id) {
                updateTandaMethod(values)
            } else {
                createTandaMethod(values)
            }
        }
        catch (err) {

        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChangeStatusTanda = async (value) => {
        console.log(`selected ${value}`);
        setStatusTanda(value)
    };

    const handleChangeMateraiTanda = async (value) => {
        console.log(`selected ${value}`);
        setStatusMaterai(value)
    };

    const getDataById = async (id) => {
        try {
            const dataTanda = await dispatch(fetchOneTanda(id)).unwrap()
            setStatusTanda(dataTanda.statusTanda)
            setStatusMaterai(dataTanda.statusMaterai)
            setImageUrl(dataTanda.externalFile)
            form.setFieldsValue({
                ...dataTanda,
                tanggalTanda: moment(dataTanda.tanggalTanda)
            });
            setTanda(dataTanda)
        } catch (err) {
            message.error(err?.message || 'Failed to fetch data')
            console.log(err)
        }
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        setLoading(true)
        try {
            const response = await fetch(
                strings.api.storage,
                {
                    method: 'POST',
                    body: formData,
                }
            );
            const data = await response.json();
            console.log(data)
            setImageUrl(data.secure_url);
            setLoading(false)
        } catch (err) {
            console.log(err)
            console.error('Error uploading image:', err);
        }
    };

    const uploading = () =>{
        if(imageUrl && !isLoading){
            return (<b style={{color:"green",marginTop:"5px"}}>File Uploaded!</b>)
        }else if(!imageUrl && !isLoading){
            return (<b style={{color:"red",marginTop:"5px"}}>File Not Uploaded!</b>)
        }else{
            return ( (<b style={{marginTop:"5px"}}><Spin />Uploading</b>))
        }
    }

    useEffect(() => {
        if (location.id) {
            getDataById(location.id)
        }
    }, [])

    // const tableColumns = [
    //     {
    //         title: 'Tanggal',
    //         dataIndex: 'tanggalSurat',
    //         render: (_, record) => {
    //             return (
    //                 moment(record.tanggalSurat).format("DD-MMM-YY")
    //             )
    //         }
    //     },
    //     {
    //         title: 'Type',
    //         dataIndex: 'type',
    //         key: 'type',
    //     },
    //     {
    //         title: 'Qty',
    //         dataIndex: 'qty',
    //         key: 'qty',
    //     },
    //     {
    //         title: 'Remarks',
    //         dataIndex: 'remarks',
    //         key: 'remarks',
    //     },
    //     {
    //         title: 'Req By',
    //         dataIndex: 'reqBy',
    //         key: 'reqBy',
    //     },
    //     {
    //         title: 'PIC',
    //         dataIndex: 'pic',
    //         key: 'pic',
    //     },
    //     {
    //         title: 'Yang Menyerahkan',
    //         dataIndex: 'ygMenyerahkan',
    //         key: 'ygMenyerahkan',
    //     },
    //     {
    //         title: 'Yang Menerima',
    //         dataIndex: 'ygMenerima',
    //         key: 'ygMenerima',
    //     },
    //     {
    //         title: 'Yang Mengetahui',
    //         dataIndex: 'ygMengetahui',
    //         key: 'ygMengetahui',
    //     },
    //     {
    //         title: 'Qty',
    //         dataIndex: 'quantity',
    //         key: 'quantity',
    //     },
    //     {
    //         title: 'Soft File',
    //         dataIndex: 'externalFile',
    //         render: (_, record) => {
    //             return (
    //                 <a target='_blank' href={record.externalFile}>Cek File</a>
    //             )
    //         },
    //     },
    //     {
    //         title: 'Hard File',
    //         dataIndex: 'hardFile',
    //         key: 'hardFile',
    //     },
    //     {
    //         title: () => <div className="text-center">Detail</div>,
    //         key: 'status',
    //         render: (_, record) => (
    //             <div className="text-center">
    //                 <a type="primary" style={{ width: "70%" }} onClick={() => {
    //                     history.push({
    //                         pathname: '/app/detail-tanda-terima-masuk',
    //                         id: record._id,
    //                     })
    //                 }} >Edit</a>
    //             </div>
    //         ),
    //     },
    // ];


    return (
        <>
            <Modal
                title="Hapus Data"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Anda yakin ingin melakukan aksi ini?</p>
            </Modal>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Tanda Terima Masuk</h2>
                    <p>Summary analisa data aplikasi kali ini</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Nomor Reg Lama"
                                name="nomorRegLama"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Nomor Reg Baru"
                                name="nomorRegBaru"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Tanda Terima"
                                name="tandaTerima"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Tanggal Tanda"
                                name="tanggalTanda"
                                rules={rules}
                            >
                                <DatePicker style={selectStyle} />
                            </Form.Item>

                            <Form.Item
                                label="Tipe"
                                name="tipe"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="PIC"
                                name="pic"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Yang Menyerahkan"
                                name="ygMenyerahkan"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Yang Menerima"
                                name="ygMenerima"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Yang Mengetahui"
                                name="ygMengetahui"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Quantity"
                                name="quantity"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Req By"
                                name="reqBy"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Hard"
                                name="hard"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Deskripsi"
                                name="deskripsi"
                                rules={rules}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                            
                            <Form.Item
                                label="Upload File (Jika File dibawah 10 mb)"
                                name="upload_file"
                            >

                                <Input type="file" onChange={handleImageUpload} />
                                {uploading()}
                            </Form.Item>

                            <Form.Item
                                label="Link External (Opsional Jika File diatas 20 mb)"
                                name="externalFile"
                            >
                                <Input defaultValue={imageUrl} />
                            </Form.Item> 

                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    {location.id ? "Update" : "Tambah"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>

                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="danger" style={{ border: "0px", color: "white" }} htmlType="submit" onClick={() => {
                        setVisible(true)
                    }} block>
                        Hapus Tanda Terima
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_TANDA_TERIMA_MASUK