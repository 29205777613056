import { strings } from 'res';
import { CashHandoverIcon, OutletIcon,CommandCenterIcon, ReportIcon, DashboardIcon, SettingIcon, SLAIcon, DeliveryIcon, OrderIcon  } from "../assets/svg/icon";

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Surat Masuk",
      path: strings.navigation.path.surat_masuk,
      title: "Surat Masuk",
      icon: DeliveryIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Surat Keluar",
      path: strings.navigation.path.surat_keluar,
      title: "Surat Keluar",
      icon: SLAIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Tanda Terima",
      path: strings.navigation.path.tanda_terima_masuk,
      title: "Tanda Terima",
      icon: OutletIcon,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: "Laporan",
    //   path: strings.navigation.path.laporan,
    //   title: "Laporan",
    //   icon: ReportIcon,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: "Cari Surat",
      path: strings.navigation.path.cari_surat,
      title: "Cari Surat",
      icon: OrderIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Cari Tanda Terima",
      path: strings.navigation.path.cari_tanda_terima,
      title: "Cari Tanda Terima",
      icon: ReportIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Pengguna",
      path: strings.navigation.path.pengguna,
      title: "Pengguna",
      icon: CommandCenterIcon,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

// const ControlTree = [{
//   key: "Pengaturan",
//   path: strings.navigation.path.settings,
//   title: "Pengaturan",
//   breadcrumb: false,
//   submenu: [
//     {
//       key: "Pengaturan",
//       path: strings.navigation.path.settings,
//       title: "Pengaturan",
//       icon: SettingIcon,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }]

const navigationConfig = [
  ...dashBoardNavTree,
  // ...ControlTree,
]

export default navigationConfig;