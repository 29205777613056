import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllByTanggalFilter, fetchTandaByJenis } from 'redux/features/tanda';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const SURAT_MASUK = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const { Search } = Input;
    const {
        list,
        selectedRows,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.tanda)

    const [startDateState, setStartDate] = useState(null)
    const [endDateState, setEndDate] = useState(null)

    const getDataWithTanggal = useCallback(async (startDate, endDate) => {
        try {
            await dispatch(fetchAllByTanggalFilter({
                startDate: startDate,
                endDate: endDate
            })).unwrap()
        } catch (error) {
            message.error('Gagal mencari data pastikan kedua tanggal sudah terisi!')
        }
    }, [dispatch])

    const getData = useCallback(async () => {
        try {
            await dispatch(fetchTandaByJenis("MASUK")).unwrap()
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: 'Tanggal',
            dataIndex: 'tanggalTanda',
            render: (_, record) => {
                return (
                    moment(record.tanggalSurat).format("DD-MMM-YY")
                )
            }
        },
        {
            title: 'Nomor Reg Lama',
            dataIndex: 'nomorRegLama',
            key: 'nomorRegLama',
        },
        {
            title: 'Nomor Reg Baru',
            dataIndex: 'nomorRegBaru',
            key: 'nomorRegBaru',
        },
        {
            title: 'Tanda Terima',
            dataIndex: 'tandaTerima',
            key: 'tandaTerima',
        },
        {
            title: 'Type',
            dataIndex: 'tipe',
            key: 'tipe',
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: 'Req By',
            dataIndex: 'reqBy',
            key: 'reqBy',
        },
        {
            title: 'PIC',
            dataIndex: 'pic',
            key: 'pic',
        },
        {
            title: 'Yang Menyerahkan',
            dataIndex: 'ygMenyerahkan',
            key: 'ygMenyerahkan',
        },
        {
            title: 'Yang Menerima',
            dataIndex: 'ygMenerima',
            key: 'ygMenerima',
        },
        {
            title: 'Yang Mengetahui',
            dataIndex: 'ygMengetahui',
            key: 'ygMengetahui',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Soft File',
            dataIndex: 'externalFile',
            render: (_, record) => {
                return (
                    <a target='_blank' href={record.externalFile}>Cek File</a>
                )
            },
        },
        {
            title: 'Hard',
            dataIndex: 'hard',
            key: 'hard',
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'status',
            render: (_, record) => (
                <div className="text-center">
                    <a type="primary" style={{ width: "70%" }} onClick={() => {
                        history.push({
                            pathname: '/app/detail-tanda-terima-masuk',
                            id: record._id,
                        })
                    }} >Edit</a>
                </div>
            ),
        },
    ];

    const onSearch = (value) => console.log(value);

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Tanda Terima</h2>
                    <p>Semua surat yang telah dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        {/* <div style={{ display: "flex" }}>
                            <Search
                                placeholder="Cari Berdasarkan Perihal"
                                onSearch={onSearch}
                                style={{
                                    width: "49%",
                                    marginRight: "2%"
                                }}
                            />
                            <DatePicker
                                onChange={(value)=>{
                                    setStartDate(moment(value).format("YYYY-MM-DD"))
                                }}
                            style={{
                                width: "24%",
                                marginRight: "2%"
                            }} placeholder='Tanggal Mulai' />
                            <DatePicker
                                onChange={(value)=>{
                                    setEndDate(moment(value).format("YYYY-MM-DD"))
                                }}
                            style={{
                                width: "24%",
                                marginRight: "2%"
                            }} placeholder='Tanggal Selesai' />
                             <Button type="primary" onClick={() => {getDataWithTanggal(startDateState,endDateState)}}>Cari</Button>
                        </div> */}
                        <Table
                            scroll={{ x: 1500, y: 300 }}
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            Row={5}
                            pagination={false}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-tanda-terima-masuk'
                        })
                    }} block>
                        Tambah Tanda Terima
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(SURAT_MASUK);
