import { Button, Card, Col, Row, Table, message } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllPengguna } from 'redux/features/pengguna';
import { getUserProfile } from 'redux/features/auth';

export const PENGGUNA = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const {
        list,
        selectedRows,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.pengguna)
    
    const profile = useSelector(state => state.auth)

    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllPengguna()).unwrap()
            console.log(list)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    const getAuth = useCallback(async () => {
        try {
            await dispatch(getUserProfile()).unwrap()
            console.log(profile)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
        getAuth()
    }, [])

    const tableColumns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: () => <div className="text-center">Detail</div>,
            key: 'status',
            render: (_, record) => (
                <div className="text-center">
                    <a type="primary" style={{ width: "70%" }} onClick={() => {
                        history.push({
                            pathname: '/app/detail-pengguna',
                            id: record._id,
                        })
                    }} >Detail</a>
                </div>
            ),
        },
    ];

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Pengguna</h2>
                    <p>Semua surat yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list || []}
                            rowKey='id'
                            pagination={false}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-pengguna'
                        })
                    }}  block>
                        Tambah Pengguna
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(PENGGUNA);
